<template>
  <b-card
    class="profile-header mb-2"
    :img-src="urlFoto"
    img-top
    img-height="340"
    alt="cover photo"
    body-class="p-0"
  >
    <div class="bg-opacity" />
    <div class="mr-2 btnEditBanner">
      <b-button
        v-if="!isLoading"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        @click="handleFileImport"
      >
        <feather-icon
          icon="EditIcon"
          class="mr-50"
        />
        <span class="align-middle">Editar Banner</span>
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        disabled
      >
        <feather-icon
          icon="SpinnerIcon"
          class="mr-50"
        />
        <span class="align-middle">Subiendo Foto...</span>
      </b-button>
      <input
        ref="filePut"
        type="file"
        style="display:none "
        @change="onFileChange($event, 'WEB_HEADER')"
      >
    </div>
    <div class="lae">

      <b-button
        v-if="!isLoadingPerfil"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="btn-icon"
        @click="handleFileImportPeril"
      >
        <feather-icon icon="EditIcon" />
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="btn-icon"
        disabled
      >
        <feather-icon
          icon="LoaderIcon"
        />
      </b-button>
      <input
        ref="filePutPerfil"
        type="file"
        style="display:none "
        @change="onFileChange($event, 'WEB_DISPLAY')"
      >
    </div>
    <!-- profile picture -->
    <div class="position-relative">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img">
          <b-img
            :src="urlFotoP"
            rounded
            thumbnail
            alt="profile photo"
          />

        </div>
        <!-- profile title -->
        <div class="profile-title ml-3">
          <h2 class="text-white">
            {{ empresa.nombreComercial }}
          </h2>
          <p class="text-white">
            {{ empresa.razonSocial }}
          </p>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->

    <!-- profile navbar -->
    <div class="profile-header-nav">
      <b-navbar
        toggleable="md"
        type="light"
      >
        <!-- toggle button -->
        <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse
          id="nav-text-collapse"
          class="mt-2"
          is-nav
        >
          <!--       <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <template #tabs-start>
              <b-nav-item
                role="presentation"
                active
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">Feed</span>
                <feather-icon
                  icon="RssIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">About</span>
                <feather-icon
                  icon="InfoIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">Photos</span>
                <feather-icon
                  icon="ImageIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
              <b-nav-item
                role="presentation"
                class="font-weight-bold"
              >
                <span class="d-none d-md-block">Friends</span>
                <feather-icon
                  icon="UsersIcon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
            </template>

            <template #tabs-end>
              <b-button
                variant="primary"
                class="ml-auto"
              >
                <feather-icon
                  icon="EditIcon"
                  class="d-block d-md-none"
                />
                <span class="font-weight-bold d-none d-md-block">Edit</span>
              </b-button>
            </template>

          </b-tabs> -->

        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
  </b-card>
</template>

<script>
import {
  BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BButton, /* BTabs, BNavItem, BButton, */
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import config from '@/services/config'
import store from '@/store'
import { useNotify } from '@/helpers/toast'

export default {
  components: {
    BCard,
    BButton,
    /* BButton, */
    /* BTabs,
    BNavItem, */
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
    empresa: {
      type: Object,
      default: () => {},
    },
    /*   idDestinoTuristico: {
      type: Number,
      default: 0,
    },
    idDirectorio: {
      type: Number,
      default: 0,
    }, */

  },
  data() {
    return {
      urlFoto: '',
      urlFotoP: '',
      pathMultimedia: `${config.pathRepo}${config.contextBO}/api/v1/multimedia`,
      fileFoto: null,
      fileFotoPerfil: null,
      isLoading: false,
      isLoadingPerfil: false,
      idDirectorio: 0,
      idDestinoTuristico: 0,
    }
  },
  watch: {
    empresa: {
      handler(newVal) {
        let url = ''
        let url2 = ''
        newVal.webHeader.forEach(element => {
          url = element.idMultimedia
        })
        newVal.webDisplay.forEach(element => {
          url2 = element.idMultimedia
        })
        if (url2 === '') {
          this.urlFotoP = require('@/assets/images/checkapp/notimage_thumb.png')
        } else {
          this.urlFotoP = `${this.pathMultimedia}/${url2}`
        }
        if (url === '') {
          this.urlFoto = require('@/assets/images/checkapp/notimage.png')
        } else {
          this.urlFoto = `${this.pathMultimedia}/${url}`
        }

        this.idDestinoTuristico = newVal.destino.idDestinoTuristico
        this.idDirectorio = newVal.idDirectorio
      },
      immediate: true,
    },
  },
  methods: {
    async onFileChange(et, seccion) {
      const [a] = et.target.files
      if (seccion === 'WEB_HEADER') {
        this.isLoading = true
        this.fileFoto = a
      } else {
        this.isLoadingPerfil = true
        this.fileFotoPerfil = a
      }
      const formData = new FormData()
      formData.append('archivo', a)
      formData.append('idDestinoTuristico', this.idDestinoTuristico)
      formData.append('idMultimedia', 0)
      formData.append('idSeccion', this.idDirectorio)
      formData.append('orientacion', 'H')
      formData.append('seccionMult', seccion)
      await store
        .dispatch('multimedia/MULTIMEDIA_CREATE_MULTIMEDIA', formData)
        .then(datos => {
          if (datos.success) {
            this.notify('Operación Exitosa', 'Se ha actualizado los datos adicionales', 'success')
            const object = JSON.parse(localStorage.getItem('userData'))

            object.display = datos.data.url
            localStorage.setItem('userData', JSON.stringify(object))
            this.$emit('refresh')
          }
        })
        .catch(err => {
          console.log(err)
          this.notify('Oops!', 'No se ha actualizado la información', 'danger')
        }).finally(() => {
          this.isLoading = false
          this.isLoadingPerfil = false
        })
    },
    handleFileImport() {
      this.$refs.filePut.click()
    },
    handleFileImportPeril() {
      this.$refs.filePutPerfil.click()
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    return { notify }
  },
}
</script>

<style>
  .lae {
    position: absolute;
    left: 136px !important;
    bottom: 23px !important;
    z-index: 5;
  }
  .btnEditBanner {
    position: absolute;
    right: 0px !important;
    bottom: 60px !important;
  }
  .bg-opacity {
    position: absolute;
    bottom: 0px !important;
    width: 100%;
    height: 200px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(6,27,65,0)), color-stop(100%,rgba(6,27,65,0.95)));
    background: -webkit-linear-gradient(top, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
    background: -o-linear-gradient(top, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
    background: -ms-linear-gradient(top, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
    background: linear-gradient(to bottom, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
  }
</style>
