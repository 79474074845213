<template>
  <b-card>
    <h5 class="mb-2">
      Datos de contacto
    </h5>
    <div>
      <div
        class="blog-categories mt-0"
      >
        <div
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-avatar
            rounded
            size="32"
            variant="light-danger"
            class="mr-75"
          >
            <feather-icon
              icon="MapPinIcon"
              size="18"
            />
          </b-avatar>

          <div class="blog-category-title text-body">
            <span style="font-size: 12px;">   {{ aboutData.direccion }}   </span>
          </div>

        </div>
      </div>
      <!-- celular -->
      <div
        class="blog-categories mt-0"
      >
        <div
          v-if="aboutObject.celular !== null && aboutObject.celular !== ''"
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-avatar
            rounded
            size="32"
            variant="light-dark"
            class="mr-75"
          >
            <feather-icon
              icon="SmartphoneIcon"
              size="18"
            />
          </b-avatar>

          <div class="blog-category-title text-body">
            <span style="font-size: 12px;">{{ aboutData.celular }}   </span>
          </div>

        </div>
      </div>
      <!-- celular -->
      <!-- telefono -->
      <div
        v-if="aboutObject.telefono !== null && aboutObject.telefono !== ''"
        class="blog-categories mt-0"
      >
        <div
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-avatar
            rounded
            size="32"
            variant="light-info"
            class="mr-75"
          >
            <feather-icon
              icon="PhoneIcon"
              size="18"
            />
          </b-avatar>

          <div class="blog-category-title text-body">
            <span style="font-size: 12px;">{{ aboutData.telefono }}   </span>
          </div>

        </div>
      </div>
      <!-- telefono -->
      <!-- email -->
      <div
        v-if="aboutObject.email !== null && aboutObject.email !== ''"
        class="blog-categories mt-0"
      >
        <div
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-avatar
            rounded
            size="32"
            variant="light-warning"
            class="mr-75"
          >
            <feather-icon
              icon="AtSignIcon"
              size="18"
            />
          </b-avatar>

          <div class="blog-category-title text-body">
            <span style="font-size: 12px;">
              {{ aboutData.email }}
            </span>
          </div>

        </div>
      </div>
      <!-- email -->
      <!-- sitio -->
      <div
        v-if="aboutObject.sitioWeb !== null && aboutObject.sitioWeb !== ''"
        class="blog-categories mt-0"
      >
        <div
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-avatar
            rounded
            size="32"
            variant="light-success"
            class="mr-75"
          >
            <feather-icon
              icon="GlobeIcon"
              size="18"
            />
          </b-avatar>

          <div class="blog-category-title text-body">
            <span style="font-size: 12px;">   {{ aboutData.sitioWeb }}   </span>
          </div>

        </div>
      </div>
      <div class="divider my-2">
        <div class="divider-text" />
      </div>

      <!-- social button -->
      <div class="auth-footer-btn d-flex justify-content-between mb-1">
        <b-button
          v-if="aboutObject.facebook !== ''"
          :href="aboutObject.facebook"
          variant="facebook"
        >
          <feather-icon icon="FacebookIcon" />
        </b-button>
        <b-button
          v-if="aboutObject.twitter !== ''"
          href="javascript:void(0)"
          variant="twitter"
        >
          <feather-icon icon="TwitterIcon" />
        </b-button>
        <b-button
          v-if="aboutObject.youtube !== ''"
          href="javascript:void(0)"
          variant="google"
        >
          <feather-icon icon="YoutubeIcon" />
        </b-button>
        <b-button
          v-if="aboutObject.instagram !== ''"
          href="javascript:void(0)"
          variant="instagram"
        >
          <feather-icon icon="InstagramIcon" />
        </b-button>
        <b-button
          v-if="aboutObject.tiktok !== ''"
          href="javascript:void(0)"
          variant="dark"
        >
          <font-awesome-icon
            icon="fa-brands fa-tiktok"
            size="16"
          />
        </b-button>
      </div>
      <!-- sitio -->
    </div>
    <div class="text-center">
      <BButton
        v-b-modal.modal-edit-about
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      >
        Editar
      </BButton>
    </div>
    <!-- <EditDatos ref="editDatosForm" /> -->
    <b-modal
      id="modal-edit-about"
      ref="editDatosForm"
      title="Editar datos de contacto"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      scrollable
    >
      <!--
      @ok="handleOk"
      @show="resetModal"
      @hidden="resetModal" -->
      <form
        ref="form"
      >
        <b-form-group
          label="Dirección"
          label-for="direccion-input"
        >
          <!--  invalid-feedback="dirección es requerida"
          required -->
          <b-form-input
            id="direccion-input"
            v-model="aboutObject.direccion"
          />
        </b-form-group>

        <b-form-group
          label="Latitud"
          label-for="latitud-input"
        >
          <b-form-input
            id="latitud-input"
            v-model="aboutObject.latitud"
          />
        </b-form-group>

        <b-form-group
          label="Longitud"
          label-for="longitud-input"
        >
          <b-form-input
            id="longitud-input"
            v-model="aboutObject.longitud"
          />
        </b-form-group>

        <b-form-group
          label="Telefono"
          label-for="telefono-input"
        >
          <b-form-input
            id="telefono-input"
            v-model="aboutObject.telefono"
          />
        </b-form-group>

        <b-form-group
          label="Celular (Whatsapp)"
          label-for="celular-input"
        >
          <b-form-input
            id="celular-input"
            v-model="aboutObject.celular"
          />
        </b-form-group>
        <b-form-group
          label="Email"
          label-for="email-input"
        >
          <b-form-input
            id="email-input"
            v-model="aboutObject.email"
          />
        </b-form-group>
        <b-form-group

          label="Sitio web"
          label-for="sitioweb-input"
        >
          <b-form-input
            id="sitioweb-input"
            v-model="aboutObject.sitioWeb"
          />
        </b-form-group>
        <b-form-group

          label="Youtube"
          label-for="youtube-input"
        >
          <b-form-input

            id="youtube-input"
            v-model="aboutObject.youtube"
          />
        </b-form-group>
        <b-form-group

          label="Twitter"
          label-for="twitter-input"
        >
          <b-form-input
            id="twitter-input"
            v-model="aboutObject.twitter"
          />
        </b-form-group>
        <b-form-group
          label="Facebook"
          label-for="facebook-input"
        >
          <b-form-input
            id="facebook-input"
            v-model="aboutObject.facebook"
          />
        </b-form-group>
        <b-form-group

          label="Instagram"
          label-for="instagram-input"
        >
          <b-form-input
            id="instagram-input"
            v-model="aboutObject.instagram"
          />
        </b-form-group>
        <b-form-group

          label="TikTok"
          label-for="tiktok-input"
        >
          <b-form-input
            id="tiktok-input"
            v-model="aboutObject.tiktok"
          />
        </b-form-group>

      </form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="mr-1"
            @click="hide"
          >
            Cancelar
          </b-button>
          <BButton
            v-if="!isLoading"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="handleSubmit"
          >
            Guardar
          </BButton>
          <BButton
            v-else
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            disabled
          >
            <feather-icon
              icon="LoaderIcon"
              class="mr-50"
            />
            <span class="align-middle">Cargando</span>
          </BButton>
        </div>
      </template>
    </b-modal>

  </b-card>
</template>

<script>
import { BCard,
  // BCardText,
  BFormGroup, BFormInput, BModal, /*  VBModal, */
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useNotify } from '@/helpers/toast'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { stringToWhatsApp } from '@/utils/string'
/* import EditDatos from './components/EditDatos.vue' */

export default {
  components: {

    BCard,
    // BCardText,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    /*  VBModal, */
    /* EditDatos, */
  },
  directives: {
    /*  'b-modal': VBModal, */
    Ripple,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      aboutObject: {
        direccion: '',
        latitud: '',
        longitud: '',
        telefono: '',
        celular: '',
        email: '',
        sitioWeb: '',
        redesSociales: '',
        youtube: '',
        twitter: '',
        instagram: '',
        tiktok: '',
        facebook: '',
      },
      isLoading: false,
    }
  },
  watch: {
    aboutData: { handler(newAbout) {
      this.aboutObject.direccion = newAbout.direccion
      this.aboutObject.latitud = newAbout.latitud
      this.aboutObject.longitud = newAbout.longitud
      this.aboutObject.telefono = newAbout.telefono
      this.aboutObject.celular = newAbout.celular
      this.aboutObject.email = newAbout.email
      this.aboutObject.sitioWeb = newAbout.sitioWeb
      this.aboutObject.facebook = this.getRedSocial('Facebook', newAbout.redesSociales)
      this.aboutObject.youtube = this.getRedSocial('Youtube', newAbout.redesSociales)
      this.aboutObject.twitter = this.getRedSocial('Twitter', newAbout.redesSociales)
      this.aboutObject.instagram = this.getRedSocial('Instagram', newAbout.redesSociales)
      this.aboutObject.tiktok = this.getRedSocial('TikTok', newAbout.redesSociales)
    },
    immediate: true },
  },

  methods: {
    activeEditDatos() {
      // this.editDatosModal = !this.editDatosModal
      this.$refs.editDatosForm.show()
    },
    hide() {
      // this.editDatosModal = !this.editDatosModal
      this.$refs.editDatosForm.hide()
    },
    getRedSocial(red, cadena) {
      let texto = ''
      const listRedes = cadena.split('###')

      const social = listRedes.find(element => element.includes(red))
      try {
        // eslint-disable-next-line prefer-destructuring
        texto = social.split('**')[1]
      } catch (error) {
        texto = ''
      }
      return texto
    },
    async handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.isLoading = true
      const directorio = JSON.parse(localStorage.getItem('empresa'))
      const newObject = {
        idDirectorio: directorio.idDirectorio,
        direccion: this.aboutObject.direccion,
        latitud: this.aboutObject.latitud,
        longitud: this.aboutObject.longitud,
        telefono: this.aboutObject.telefono,
        celular: stringToWhatsApp(this.aboutObject.celular),
        email: this.aboutObject.email,
        sitioWeb: this.aboutObject.sitioWeb,
        redesSociales: `Facebook**${this.aboutObject.facebook}###Youtube**${this.aboutObject.youtube}###Twitter**${this.aboutObject.twitter}###Instagram**${this.aboutObject.instagram}###TikTok**${this.aboutObject.tiktok}` }

      await store
        .dispatch('panel/EMPRESA_UPDATE_CONTACTO', newObject)
        .then(() => {
          this.$refs.editDatosForm.hide()
          this.notify('Operación Exitosa', 'Se ha actualizado los servicios', 'success')
          this.$emit('refresh')
        })
        .catch(() => {
          this.notify('Oops!', 'Ha sucedido algo vuelva a intentarlo', 'danger')
        }).finally(() => { this.isLoading = false })
    },
  },

  setup(props, context) {
    const { notify } = useNotify(context)
    const editDatosModal = ref(false)

    return {
      notify,
      editDatosModal,
    }
  },
}
</script>
