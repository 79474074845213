<template>
  <div>
    <b-card v-if="!editActive">
      <h5 class="mb-2">
        Descripción del servicio principal
      </h5>
      <b-card-text>
        <span v-html="empresa.descripcion" />
      </b-card-text>

      <div class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"

          variant="primary"
          @click="editActiv"
        >
          Editar
        </b-button>
      </div>
    </b-card>
    <b-card
      v-else
    >
      <b-form-group>
        <h5 class="mb-2">
          Editar descripción del servicio principal
        </h5>
        <b-form-group
          label-for="descripcion"
        >
          <b-form-textarea
            id="descripcion"
            v-model="vComment"
            rows="8"
          />
        </b-form-group>

        <div class="text-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"

            variant="secondary"
            class="mr-1"
            @click="editActive= false"
          >
            Cancel
          </b-button>
          <BButton
            v-if="!isLoading"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="saveComment"
          >
            Guardar
          </BButton>
          <BButton
            v-else
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            disabled
          >
            <feather-icon
              icon="LoaderIcon"
              class="mr-50"
            />
            <span class="align-middle">Cargando</span>
          </BButton>
        </div>
      </b-form-group></b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, VBTooltip, BButton, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
// import { quillEditor } from 'vue-quill-editor'
import store from '@/store'
import { useNotify } from '@/helpers/toast'
import { removeTags } from '@/utils/string'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    // quillEditor,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    empresa: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    editActiv() {
      this.vComment = removeTags(this.empresa.descripcion)
      this.editActive = true
    },

    async saveComment() {
      const directorio = JSON.parse(localStorage.getItem('empresa'))
      this.isLoading = true
      await store.dispatch('panel/EMPRESA_UPDATE_DESCRIPCION', {
        descripcion: this.vComment,
        idDirectorio: directorio.idDirectorio,
      }).then(() => {
        this.$emit('refresh')
        this.notify('Operación Exitosa', 'Se ha actualizado la descripción', 'success')
        this.editActive = false
      })
        .catch(() => {
          this.notify('Oops!', 'Ha sucedido algo vuelva a intentarlo', 'danger')
        }).finally(() => {
          this.isLoading = false
        })
    },

  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const isLoading = ref(false)
    const editActive = ref(false)
    const vComment = ref('')
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      // ['blockquote', 'code-block'],
      // [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      // [{ script: 'sub' }, { script: 'super' }],
      // [{ indent: '-1' }, { indent: '+1' }],
      // [{ direction: 'rtl' }],
      // [{ size: ['small', false, 'large', 'huge'] }],
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{ color: [] }, { background: [] }],
      // [{ font: [] }],
      [{ align: [] }],
      // ['clean'],
    ]
    const editorOption = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Ingrese descripción',
    }
    return {
      editActive,
      vComment,
      editorOption,
      isLoading,
      notify,
      removeTags,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
[dir=ltr] .ql-snow .ql-icon-picker .ql-picker-label svg {
  margin-top: -4px;
}
</style>
