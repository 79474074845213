<template>
  <b-card>
    <h5 class="mb-0">
      Fotos
    </h5>
    <b-row v-if="listFotos.length > 0">
      <!-- latest photo loop -->

      <b-col
        v-for="data in listFotos"
        :key="data.img"
        md="4"
        cols="6"
        class="profile-latest-img"
      >
        <b-link>
          <b-img
            fluid
            rounded
            :src="data.img"
            :alt="nombre"
          />
        </b-link>
      </b-col>

      <!-- latest photo loop -->
    </b-row>
    <div
      v-else
      class="text-center mt-2"
    >
      <h4>No hay fotos registradas</h4>
    </div>

    <div
      class="text-center"
    >
      <!-- open modalEditFoto -->
      <BButton
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1"
        @click="openModalEditFoto"
      >
        Editar
      </BButton>
    </div>
    <div>
      <b-modal
        id="modalEditFotoS"
        ref="modalEditFoto"
        title="Gestión Multimedia"
        :hide-footer="true"
        :hide-header="true"
        no-close-on-backdrop
        no-close-on-esc
        size="lg"
        :ok-disabled="true"
      >
        <!-- buttons aceptar, cancelar, two buttons -->

        <div class="d-flex justify-content-end">
          <div>
            <b-button
              variant="primary"
              class="mr-1"
              @click="aceptar"
            >
              Aceptar
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="closeModalEditFoto"
            >
              Cerrar
            </b-button>
          </div>
        </div>
        <!-- :busy="isBusy" -->
        <!-- <b-alert
          variant="primary"
          show
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              :icon="dataInfo.alertIcon"
            />
            <span class="ml-25"><strong>{{ dataInfo.alertMsgTitle }}</strong> {{ dataInfo.alertMsgSubTitle }}</span>
          </div>
        </b-alert> -->
        <file-pond
          ref="pondEdit"
          name="archivo"
          label-idle="Arrastre los archivos aquí o <span class='filepond--label-action'>Seleccionelos</span>"
          allow-multiple="true"
          allow-reorder="true"
          accepted-file-types="image/jpeg, image/png"
          :files="listFotos"
          :server="myServer"
          @reorderfiles="reorderFiles"
        />
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BLink, BImg, BButton,
} from 'bootstrap-vue'

import config from '@/services/config'
import store from '@/store'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { useNotify } from '@/helpers/toast'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
)
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BImg,
    BButton,
    FilePond,
  },
  props: {
    latestImages: {
      type: Array,
      default: () => [],
    },
    nombre: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      pathMultimedia: `${config.pathRepo}${config.contextBO}/api/v1/multimedia`,
      listFotos: [],
      copyListFoto: [],
      isEdit: false,
      isLoading: false,
      myFiles: [],
      myServer: {
        url: `${config.pathRepo}${config.contextBO}/api/v1/`,
        timeout: 7000,
        process: {
          url: 'multimedia',
          method: 'POST',
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          onload: response => response.key,
          onerror: response => response.data,
          ondata: formData => {
            const directorio = JSON.parse(localStorage.getItem('empresa'))
            formData.append('seccionMult', 'WEB_DESTACADAS')
            formData.append('orientacion', 'H')
            formData.append('idDestinoTuristico', directorio.destino.idDestinoTuristico) /* empre get destino */
            formData.append('idMultimedia', 0)
            formData.append('idSeccion', directorio.idDirectorio) /* idDirectorio */
            return formData
          },
        },
        revert: this.onRemoveFile,
        load: (source, load) => {
          const myRequest = new Request(source)
          fetch(myRequest).then(response => {
            response.blob().then(myBlob => {
              load(myBlob)
            })
          })
        },
        remove: this.onRemoveFile,
      },
    }
  },
  watch: {
    /* latestImages(photo) {
      this.listFotos = []
      photo.forEach(screen => {
        this.listFotos.push({ img: `${this.pathMultimedia}/${screen.idMultimedia}/thumbnail`,
          id: screen.idMultimedia,
          source: `${this.pathMultimedia}/${screen.idMultimedia}/thumbnail`,
          options: {
            type: 'local',
          } })
      })
    }, */

    latestImages: {
      handler(photo) {
        this.listFotos = []
        photo.forEach(screen => {
          this.listFotos.push(
            { img: `${this.pathMultimedia}/${screen.idMultimedia}/thumbnail`,
              id: screen.idMultimedia,
              source: `${this.pathMultimedia}/${screen.idMultimedia}/thumbnail`,
              options: {
                type: 'local',
              },
            },
          )
        })
      },
      // force eager callback execution
      immediate: true,
    },
  },
  methods: {
    onFileAdded(error, file) {
      try {
        console.log('archivo subido ', file)
        // this.copyListFoto.push(file.source)
      } catch (deadlock) {
        console.log(deadlock)
      }
      /* this.myFiles.push(file.source) */
    },
    openModalEditFoto() {
      this.$refs.modalEditFoto.show()
    },
    aceptar() {
      this.$emit('refresh')
      this.$refs.modalEditFoto.hide()
    },
    closeModalEditFoto() {
      this.$refs.modalEditFoto.hide()
    },

    async reorderFiles(files) {
      let idsMult = ''
      files.forEach(file => {
        const paramsUrl = file.serverId.split('/')
        const idMult = paramsUrl[paramsUrl.length - 2]
        if (idsMult.length !== 0) {
          idsMult += '&'
        }
        idsMult += `multimedias%5B%5D=${idMult}`
      })
      await store
        .dispatch('multimedia/MULTIMEDIA_SORT', {
          multimedias: idsMult,
        })
        .then(() => {
          this.notify('Operación Exitosa', 'Se ha reordenado los las fotos', 'success')
        })
        .catch(() => {
          this.notify('Oops!', 'No se ha actualizado la foto', 'danger')
        })
    },
    async onRemoveFile(source) {
      try {
        /* find source in this.myFiles of file.source */
        const removeMulti = this.listFotos.find(item => item.source === source)
        /* delete from this.myFiles */

        await store
          .dispatch('multimedia/MULTIMEDIA_DELETE', {
            id: removeMulti.id,
          })
          .then(response => {
            if (response.success) {
              this.listFotos = this.listFotos.filter(item => item.source !== source)
              this.notify('Operación Exitosa', 'Se ha eliminado la foto', 'success')
            }
          })
          .catch(() => {
            this.notify('Oops!', 'No se ha eliminado la foto', 'danger')
          })
      } catch (errCatch) {
        console.log(errCatch)
      }
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    return { notify }
  },
}
</script>
