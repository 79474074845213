<template>
  <div>
    <b-card>
      <h5 class="mb-2">
        Brochure (PDF con presentación de tus servicios)
      </h5>

      <div class="text-center">
        <b-button
          v-if="empresa.brochure && empresa.brochure.includes('files/brochure')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-2"
          target="_blank"
          :href="PATH_PDF_CONTROLLER + empresa.brochure"
        >
          Descargar
        </b-button>
        <input
          ref="fileInput"
          type="file"
          style="display: none"
          accept="application/pdf"
          @change="handleFileChange"
        >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          @click="openFileInput"
        >
          Editar
        </b-button>
        <b-button
          v-if="empresa.brochure && empresa.brochure.includes('files/brochure')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          @click="deleteBrochure"
        >
          Quitar
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { useNotify } from '@/helpers/toast'

export default {
  components: {
    BCard,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    empresa: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click()
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0]
      this.updateBrochure(selectedFile)
    },
    async updateBrochure(file) {
      const directorio = JSON.parse(localStorage.getItem('empresa'))
      const formData = new FormData()
      formData.append('idDirectorio', directorio.idDirectorio)
      formData.append('pdf', file)

      this.isLoading = true
      await store.dispatch('panel/EMPRESA_UPDATE_BROCHURE', formData).then(() => {
        this.$emit('refresh')
        this.notify('Operación Exitosa', 'Se ha actualizado la descripción', 'success')
      })
        .catch(() => {
          this.notify('Oops!', 'Ha sucedido algo vuelva a intentarlo', 'danger')
        }).finally(() => {
          this.isLoading = false
        })
    },
    async deleteBrochure() {
      const directorio = JSON.parse(localStorage.getItem('empresa'))
      this.$swal({
        title: 'Está seguro?',
        text: 'Va quitar el brochure!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('panel/EMPRESA_DELETE_BROCHURE', {
              id: directorio.idDirectorio,
            })
            .then(response => {
              this.$emit('refresh')
              this.$bvToast.toast('Ha Eliminado correctamente el brochure.', {
                title: 'Operación Exitosa',
                variant: 'success',
                solid: true,
              })
              return response
            })
            .catch(error => {
              this.$bvToast.toast(error, {
                title: 'Ha ocurrido un error',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
  },
  setup(props, context) {
    const PATH_PDF_CONTROLLER = ref('https://api-pi.visitasanmartin.pe/api-visitasm-pi/api/v1/pdf?url=')
    const { notify } = useNotify(context)
    const isLoading = ref(false)
    const editActive = ref(false)
    const vComment = ref('')
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      // ['blockquote', 'code-block'],
      // [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      // [{ script: 'sub' }, { script: 'super' }],
      // [{ indent: '-1' }, { indent: '+1' }],
      // [{ direction: 'rtl' }],
      // [{ size: ['small', false, 'large', 'huge'] }],
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{ color: [] }, { background: [] }],
      // [{ font: [] }],
      [{ align: [] }],
      // ['clean'],
    ]
    const editorOption = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Ingrese descripción',
    }
    return {
      editActive,
      vComment,
      editorOption,
      isLoading,
      notify,
      PATH_PDF_CONTROLLER,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
[dir=ltr] .ql-snow .ql-icon-picker .ql-picker-label svg {
  margin-top: -4px;
}
</style>
