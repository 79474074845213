<template>
  <b-card
    class="card-statistics"
  >
    <h5 class="mb-2">
      Video de presentación
    </h5>

    <b-card-body>

      <div v-if="urlVideo !== null && urlVideo !== ''">
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="urlVideo"
          allowfullscreen
        />
      </div>
      <div
        v-else-if="!isEdit"
        class="text-center"
      >
        <h4>No hay un video registrado</h4>

      </div>
      <br>
      <b-form-group
        v-if="isEdit"
        label="Video de Youtube"
        label-for="video-input"
      >
        <small>Ejem: https://www.youtube.com/embed/Fo4TA15J9oI</small>
        <br />
        <small>Recuerda que el video debe ser público</small>
        <br />
        <br />
        <b-form-input
          id="video-input"
          v-model="urlVideo"
        />
      </b-form-group>
      <div
        v-if="isEdit"
        class="text-center"
      >
        <BButton
          v-if="!isLoading"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="handleOk"
        >
          Guardar
        </BButton>
        <BButton
          v-else
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          disabled
        >
          <feather-icon
            icon="LoaderIcon"
            class="mr-50"
          />
          <span class="align-middle">Cargando</span>
        </BButton>
      </div>
      <div
        v-else
        class="text-center"
      >
        <BButton

          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1"
          @click="isEdit = true"
        >
          Editar
        </BButton>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BCardBody,
  BEmbed,
  BCard } from 'bootstrap-vue'
import store from '@/store'
import { useNotify } from '@/helpers/toast'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BCardBody,
    BEmbed,
  },
  props: {
    empresa: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      isEdit: false,
      listBethRooms: [],
      listColors: [
        'light-info',
        'light-danger',
        'light-primary',
        'light-warning',
        'light-success'],
      urlVideo: '',
    }
  },
  watch: {
    empresa: {
      handler(newVal) {
        this.urlVideo = newVal.videoPresentacion
      },
      immediate: true,
    },
  },
  methods: {
    getSplitTextList(cadena) {
      const list = cadena.split('-')
      return list
    },
    async  handleOk() {
      const directorio = JSON.parse(localStorage.getItem('empresa'))
      this.isLoading = true
      await store.dispatch('panel/EMPRESA_UPDATE_VIDEO', {
        idDirectorio: directorio.idDirectorio,
        videoPresentacion: this.urlVideo,
      }).then(due => {
        if (due.success) {
          this.$emit('refresh')
          this.notify('Operación Exitosa', 'Se ha actualizado el video', 'success')
          this.isEdit = false
        }
        /* https://www.youtube.com/embed/Fo4TA15J9oI */
      })
        .catch(() => {
          this.notify('Oops!', 'Ha sucedido algo vuelva a intentarlo', 'danger')
        }).finally(() => { this.isLoading = false })
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)

    return {
      notify,
    }
  },
}
</script>
