<template>
  <b-card>
    <h5 class="mb-2">
      Servicios
    </h5>
    <!-- user suggestion  -->
    <div v-if="!isLoading">
      <div
        v-if="listServicios.length > 0 "
        class="blog-categories mt-0"
      >
        <div
          v-for="category in listServicios"
          :key="category.nombre"
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-link>
            <b-avatar
              rounded
              size="32"
              :variant="category.color"
              class="mr-75"
            >
              <font-awesome-icon
                :icon="category.icon"
              />
            </b-avatar>
          </b-link>
          <b-link>
            <div class="blog-category-title text-body">
              {{ category.nombre }}
            </div>
          </b-link>
        </div>
      </div>
      <div
        v-else
        class="text-center"
      >
        <h4>No hay servicios registrados</h4>
      </div>
      <div class="text-center">
        <BButton
          v-b-modal.modal-edit-servicios
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="getClassServicios"
        >
          Editar
        </BButton>
      </div>
    </div>
    <div
      v-else
      class="text-center"
    >  <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      label="Large Spinner"
      variant="primary"
    /></div>
    <!--/ user suggestion  -->
    <b-modal
      id="modal-edit-servicios"
      ref="editServiciosForm"
      title="Editar Servicios"
      ok-title="Guardar"
      cancel-variant="outline-secondary"
      size="sm"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="mr-1"
            @click="hide"
          >
            Cancelar
          </b-button>
          <BButton
            v-if="!isLoadingPostServ"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="handleOk"
          >
            Guardar
          </BButton>
          <BButton
            v-else
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            disabled
          >
            <feather-icon
              icon="LoaderIcon"
              class="mr-50"
            />
            <span class="align-middle">Cargando</span>
          </BButton>
        </div>
      </template>
      <!--

      @show="resetModal"
      @hidden="resetModal" -->
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group

          label="Servicios"
          label-for="direccion-input"
          invalid-feedback="dirección es requerida"
        >
          <v-select
            v-model="vServicios"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="listClassServicios"
            :clearable="false"
            label="nombre"
            :close-on-select="false"
            :reduce="option => option.idClasificacion"
            multiple
            input-id="servicios"
            required
          />
        </b-form-group>

      </form>
    </b-modal>
  </b-card>
</template>

<script>
import store from '@/store'
import {
  BCard, BAvatar, BButton, BLink, BSpinner, BModal, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { useNotify } from '@/helpers/toast'

export default {
  components: {
    BCard,
    BAvatar,
    BButton,
    BLink,
    BSpinner,
    BModal,
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    /*    suggestions: {
      type: Array,
      default: () => [],
    }, */
  },
  data() {
    return {
      listColors: [
        'light-info',
        'light-danger',
        'light-primary',
        'light-warning',
        'light-success'],
    }
  },
  mounted() {
    this.getServicios()
  },
  methods: {
    async getClassServicios() {
      await store.dispatch('catalogo/CLASIFICACION_FIND_ALL_BY_SECCION', {
        seccion: 'AMENIDADES_SERVTUR',
      }).then(due => {
        if (due.success) {
          this.listClassServicios = due.data
        }
      })
        .catch(error => {
          console.log(error)
        }).finally(() => { this.isLoading = false })
    },

    async getServicios() {
      this.isLoading = true
      await store
        .dispatch('panel/EMPRESA_FIND_ALL_SERV_ADIC_DIRECTORIO')
        .then(due => {
          let color = 0
          this.listServicios = []
          this.vServicios = []
          due.forEach(item => {
            this.listServicios.push({ color: this.listColors[color], nombre: item.clasificacion.nombre, icon: item.clasificacion.icono })
            this.vServicios.push(item.clasificacion.idClasificacion)
            color += 1
            if (color === 4) {
              color = 0
            }
          })
        })
        .catch(error => {
          console.log(error)
        }).finally(() => { this.isLoading = false })
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.isLoadingPostServ = true
      await store
        .dispatch('panel/EMPRESA_CHECK_DETALLES_UPDATE', {
          ids: this.vServicios.join(','),
        })
        .then(() => {
          this.$refs.editServiciosForm.hide()
          this.notify('Operación Exitosa', 'Se ha actualizado los servicios', 'success')
          this.getServicios()
        })
        .catch(() => {
          this.notify('Oops!', 'Ha sucedido algo vuelva a intentarlo', 'danger')
        }).finally(() => { this.isLoadingPostServ = false })
    },
    hide() {
      this.$refs.editServiciosForm.hide()
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const isLoading = ref(false)
    const isLoadingPostServ = ref(false)
    const listServicios = ref([])
    const listClassServicios = ref([])
    const vServicios = ref([])
    return { isLoading, listServicios, listClassServicios, vServicios, isLoadingPostServ, notify }
  },
}
</script>
