<template>
  <b-card
    class="card-statistics"
  >
    <h5
      class="mb-2"
    >
      Datos adicionales
    </h5>
    <b-row>
      <BCol
        lg="12"
        cols="12"
        order="1"
        order-lg="1"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ empresa.asociacionPertenece }}
              </h2>
              <span>Asociación de turismo a la que pertenece</span>
            </div>
            <b-avatar
              :variant="`light-info`"
              size="45"
            >
              <font-awesome-icon
                icon=" fa-solid fa-building"
                size="lg"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </BCol>
      <BCol
        lg="12"
        cols="12"
        order="2"
        order-lg="1"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ formatCalificacion(empresa.califacionCalidad) }}
              </h2>
              <span>Clasificación</span>
            </div>
            <b-avatar
              :variant="`light-success`"
              size="45"
            >
              <font-awesome-icon
                icon="fa-solid fa-tags"
                size="lg"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </BCol>
      <BCol
        lg="12"
        cols="12"
        order="2"
        order-lg="1"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ empresa.otrosEquipos }}
              </h2>
              <span>Otros Equipos</span>
            </div>
            <b-avatar
              :variant="`light-warning`"
              size="45"
            >
              <font-awesome-icon
                icon="fa-solid fa-helmet-safety"
                size="lg"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </BCol>
      <BCol
        lg="12"
        cols="12"
        order="2"
        order-lg="1"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ empresa.vehiculoPropio ? 'Si': 'No' }}
              </h2>
              <span>Vehiculo Personal</span>
            </div>
            <b-avatar
              :variant="`light-danger`"
              size="45"
            >
              <font-awesome-icon
                icon="fa-solid fa-car"
                size="lg"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </BCol>
    </b-row>

    <div class="text-center">
      <BButton
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="openAddBethroom"
      >
        Editar
      </BButton>
    </div>
    <!-- Modal de insert camas -->
    <b-modal
      id="modal-bethrooms"
      ref="insertBethrooms"
      centered
      title="Editar datos adicionales"
      no-close-on-backdrop
      size="m"
    >
      <template #modal-footer>
        <div class="float-right">
          <b-button-group size="sm">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mr-1"
              variant="secondary"
              @click="closeModal"
            >
              <feather-icon
                class="mr-50"
                icon="XIcon"
              />
              <span class="align-middle">Cancelar</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="saveBethRooms"
            >
              <feather-icon
                class="mr-50"
                icon="CheckIcon"
              />
              <span class="align-middle">Guardar</span>
            </b-button>
          </b-button-group>
        </div>
      </template>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <BRow>
          <BCol
            lg="12"
            cols="12"
            order="1"
            order-lg="1"
          >
            <b-form-group
              label="Asociación de turismo a la que pertenece"
              label-for="asociacion-input"
            >
              <b-form-input
                id="asociacion-input"
                v-model="formAgencias.asociacionPertenece"
              />
            </b-form-group>
          </BCol>

          <BCol
            lg="12"
            cols="12"
            order="2"
            order-lg="2"
          >
            <b-form-group
              label="Clasificación"
              label-for="calificacion-input"
            >
              <v-select
                id="calificacion-input"
                v-model="formAgencias.califacionCalidad"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clasificacionOpts"
                multiple
              />
            </b-form-group>
          </BCol>
          <!--  formAgencias: {
        asociacionPertenece: '',
        califacionCalidad: '',
        estado: '',
        otrosEquipos: '',
        vehiculoPropio: false,
      }, -->
          <BCol
            lg="12"
            cols="12"
            order="3"
            order-lg="3"
          >
            <b-form-group
              label="Otros Equipos"
              label-for="equipos-input"
            >
              <b-form-input
                id="equipos-input"
                v-model="formAgencias.otrosEquipos"
              />
            </b-form-group>
          </BCol>
          <BCol
            lg="12"
            cols="12"
            order="4"
            order-lg="4"
          >
            <b-form-group
              label="Vehículo propio"
              label-for="vehiculo-input"
            >
              <b-form-checkbox
                v-model="formAgencias.vehiculoPropio"
                :checked="formAgencias.vehiculoPropio"
                switch
              >
                {{ formAgencias.vehiculoPropio ? 'SÍ' : 'NO' }}
              </b-form-checkbox>
            </b-form-group>
          </BCol>

        </BRow>

      </b-form>

      <!--   <b-card-text> qwe </b-card-text> -->
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  /* BMedia,
  BMediaAside,
  BMediaBody, */
  BFormCheckbox,
  BAvatar,
  BModal,
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useNotify } from '@/helpers/toast'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BModal,
    BCardBody,
    BAvatar,
    BFormCheckbox,
    /*    BMedia,
    BMediaAside,
    BMediaBody, */
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BButtonGroup,
    vSelect,
  },

  directives: {
    /*  'b-modal': VBModal, */
    Ripple,
  },
  props: {
    empresa: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editListBethRooms: [],
      ListBethRoomsShow: [],
      listColors: [
        'light-info',
        'light-danger',
        'light-primary',
        'light-warning',
        'light-success'],
      items: [],
      isEdit: false,
      isLoading: false,
      formAgencias: {
        asociacionPertenece: '',
        califacionCalidad: '',
        estado: '',
        otrosEquipos: '',
        vehiculoPropio: false,
      },
      id: 0,
      clasificacionOpts: ['Minorista', 'Mayorista', 'Operador de Turismo'],
    }
  },
  watch: {
    empresa(newVal) {
      this.formAgencias.asociacionPertenece = newVal.asociacionPertenece
      this.formAgencias.califacionCalidad = newVal.califacionCalidad.includes(',') ? newVal.califacionCalidad?.split(',') : newVal.califacionCalidad
      this.formAgencias.otrosEquipos = newVal.otrosEquipos
      this.formAgencias.vehiculoPropio = newVal.vehiculoPropio
    },
  },
  mounted() {
  },
  methods: {
    formatCalificacion(calificacion) {
      if (calificacion && calificacion.includes(',')) {
        return calificacion.replaceAll(',', ', ')
      }
      return calificacion
    },
    openAddBethroom() {
      this.$refs.insertBethrooms.show()
    },
    async saveBethRooms() {
      if (this.formAgencias.califacionCalidad?.length === 0) {
        this.notify('Oops!', 'No se ha seleccionado clasificación', 'warning')
        return
      }
      this.isLoading = true

      const directorio = JSON.parse(localStorage.getItem('empresa'))

      const object = {
        idDatoAdicionalAgencia: this.empresa.idDatoAdicionalAgencia,
        idDirectorio: directorio.idDirectorio,
        asociacionPertenece: this.formAgencias.asociacionPertenece,
        califacionCalidad: Array.isArray(this.formAgencias.califacionCalidad) ? this.formAgencias.califacionCalidad?.join(',') : this.formAgencias.califacionCalidad,
        otrosEquipos: this.formAgencias.otrosEquipos,
        vehiculoPropio: this.formAgencias.vehiculoPropio,
        estado: true,
      }
      await store
        .dispatch('panel/EMPRESA_POST_DATO_ADICIONAL', { model: object, tipo: 'AGENCIA' })
        .then(datos => {
          if (datos.success) {
            this.notify('Operación Exitosa', 'Se ha actualizado los datos adicionales', 'success')
            this.$refs.insertBethrooms.hide()
            this.$emit('refresh')
          }
        })
        .catch(() => {
          this.notify('Oops!', 'No se ha actualizado la información', 'danger')
        }).finally(() => { this.isLoading = false })
    },
    closeModal() {
      this.$refs.insertBethrooms.hide()
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    return {
      notify,
    }
  },
}
</script>
