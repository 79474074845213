<template>
  <b-card class="p-0">
    <h5 class="mb-2">
      Horario de atención
    </h5>
    <BCol
      v-if="!isEmpty"
      lg="12"
      cols="12"
      md="12"
      sm="12"
      class="p-0"
    >
      <!-- user suggestion  -->
      <div
        v-if="horarioAtencionShow.length > 0"
        class="d-flex-column text-center p-0 m-0"
      >
        <BBadge
          v-for="diasLabo in horarioAtencionShow"
          :key="diasLabo.id"
          :class="diasLabo.color"
          class="font-size-11"
          style="margin-bottom: 15px"
          v-html="diasLabo.value"
        />
      </div>
      <div v-else>
        <BBadge
          class="font-size-11"
          :class="horarioText !== 'SIEMPRE ABIERTO' ? listColors[3] : listColors[0]"
        >
          {{ horarioText }}
        </BBadge>
      <!--
  badge-light-danger -->
      </div>
    </BCol>
    <BCol
      v-else
      lg="12"
      cols="12"
      md="12"
      sm="12"
      class="p-0"
    >
      <div class="text-center">
        <h4>No hay horario de atención registrados</h4>
      </div>
    </BCol>
    <div v-if="!isLoading">
      <div
        class="blog-categories mt-0"
      />
      <div class="text-center mt-1">
        <BButton
          v-b-modal.modal-edit-horario
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        >
          Editar
        </BButton>
      </div>
    </div>
    <div
      v-else
      class="text-center"
    >  <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      label="Large Spinner"
      variant="primary"
    /></div>
    <!--/ user suggestion  -->
    <b-modal
      id="modal-edit-horario"
      ref="editServiciosForm"
      title="Editar Horarios de Atencion"
      ok-title="Guardar"
      cancel-variant="outline-secondary"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="handleOk"
    >
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="mr-1"
            @click="hide"
          >
            Cancelar
          </b-button>
          <BButton
            v-if="!isLoading"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="handleOk"
          >
            Guardar
          </BButton>
          <BButton
            v-else
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            disabled
          >
            <feather-icon
              icon="LoaderIcon"
              class="mr-50"
            />
            <span class="align-middle">Cargando</span>
          </BButton>
        </div>
      </template>
      <!--

      @show="resetModal"
      @hidden="resetModal" -->
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group

          label="Servicios"
          label-for="direccion-input"
          invalid-feedback="dirección es requerida"
        >
          <v-select
            v-model="selectListCombo"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="listCombo"
            :clearable="false"
            label="value"
            input-id="horarioOptions"
            required
            @change="cambiandoOption"
          />
        </b-form-group>
        <div v-if="selectListCombo.id ==='AHC***'"> <BRow
          v-for="hora in formHorario"

          :key=" hora.id"
          class="mt-1"
        >
          <BCol
            lg="2"
            cols="12"
            order="2"
            order-lg="1"
          >
            <b-form-checkbox
              v-model="hora.active"
              @change="cleanHorario(hora)"
            >
              {{ hora.nombre }}
            </b-form-checkbox>
          </BCol>

          <BCol
            lg="4"
            cols="12"
            order="2"
            order-lg="1"
          >
            <b-form-timepicker
              v-model="hora.horario[0].desde"
            />
            <b-form-timepicker
              v-if="hora.horario[1].active"
              v-model="hora.horario[1].desde"
              class="mt-1"
            />
          </BCol>
          <BCol
            lg="4"
            cols="12"
            order="2"
            order-lg="1"
          >
            <b-form-timepicker

              v-model="hora.horario[0].hasta"
            />
            <b-form-timepicker
              v-if="hora.horario[1].active"
              v-model="hora.horario[1].hasta"
              class="mt-1"
            />
          </BCol>
          <!-- bcol vertical align end items -->

          <b-col
            lg="2"
            cols="12"
            order="2"
            order-lg="1"
            class="d-flex align-items-end"
          >
            <b-button
              v-if="!hora.horario[1].active"
              variant="primary"
              @click="hora.horario[1].active= true"
            >
              <feather-icon
                icon="PlusIcon"
                class="m-0"
              />
            </b-button>
            <div
              v-else
            >
              <b-button
                variant="danger"
                @click="(hora.horario[1].active= false)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="m-0"
                />
              </b-button>
            </div>
          </b-col>
        </BRow>
        </div>

      </form>
    </b-modal>
  </b-card>
</template>

<script>
import store from '@/store'
import {
  BCard,
  /* BAvatar,
  BLink,
  */
  BBadge,
  BButton,
  BSpinner,
  BModal,
  BFormGroup,
  BFormTimepicker,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { useNotify } from '@/helpers/toast'
import convertTimeFormat from '@/utils/time'

export default {
  components: {
    BCard,
    /*    BAvatar,
    BLink,
    */
    BBadge,
    BFormTimepicker,
    BButton,
    BSpinner,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    horario: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      listColors: [
        'badge-light-success',
        'badge-light-primary',
        'badge-light-info',
        'badge-light-danger',
        'badge-light-warning',
      ],
      listHorario: '',
      listCombo: [
        { id: 'AHC***', value: 'Abierto en horas concretas' },
        { id: 'HSA***', value: 'SIEMPRE ABIERTO' },
        { id: 'HND***', value: 'Horario no disponible' },
      ],
      selectListCombo: { id: 'AHC***', value: 'Abierto en horas concretas' },
      listDays: [
        { id: 1, value: 'Lunes' },
        { id: 2, value: 'Martes' },
        { id: 3, value: 'Miercoles' },
        { id: 4, value: 'Jueves' },
        { id: 5, value: 'Viernes' },
        { id: 6, value: 'Sabado' },
        { id: 7, value: 'Domingo' },
      ],
      formHorario: [
        { id: 1,
          nombre: 'LUNES',
          active: false,
          horario: [
            { id: 'a', desde: '', hasta: '', active: true },
            { id: 'b', desde: '', hasta: '', active: false },
          ],
        },
        { id: 2,
          nombre: 'MARTES',
          active: false,
          horario: [
            { id: 'c', desde: '', hasta: '', active: true },
            { id: 'd', desde: '', hasta: '', active: false },
          ],
        },
        { id: 3,
          nombre: 'MIERCOLES',
          active: false,
          horario: [
            { id: 'e', desde: '', hasta: '', active: true },
            { id: 'f', desde: '', hasta: '', active: false },
          ],
        },
        { id: 4,
          nombre: 'JUEVES',
          active: false,
          horario: [
            { id: 'g', desde: '', hasta: '', active: true },
            { id: 'h', desde: '', hasta: '', active: false },
          ],
        },
        { id: 5,
          nombre: 'VIERNES',
          active: false,
          horario: [
            { id: 'j', desde: '', hasta: '', active: true },
            { id: 'k', desde: '', hasta: '', active: false },
          ],
        },
        { id: 6,
          nombre: 'SABADO',
          active: false,
          horario: [
            { id: 'l', desde: '', hasta: '', active: true },
            { id: 'm', desde: '', hasta: '', active: false },
          ],
        },
        { id: 7,
          nombre: 'DOMINGO',
          active: false,
          horario: [
            { id: 'n', desde: '', hasta: '', active: true },
            { id: 'o', desde: '', hasta: '', active: false },
          ],
        },

      ],
      horarioListString: '',
      validatorSubmit: true,
      horarioAtencionShow: [],
      horarioText: '',
      isEmpty: false,
    }
  },
  watch: {
    horario: {
      handler(val) {
        this.isEmpty = false
        let color = 0
        this.formHorario = this.resetForm()
        this.horarioAtencionShow = []
        try {
          if (val === '') {
            this.isEmpty = true
            return
          }

          const [selectionable, diasHorarios] = val.split('***')
          this.selectListCombo = this.listCombo.find(hour => hour.id === `${selectionable}***`)
          if (this.selectListCombo.id === 'AHC***') {
            const secondSplit = diasHorarios.split('**')
            secondSplit.forEach(horarios => {
              const [dia, horario] = horarios.split(',')

              const elegido = this.formHorario.findIndex(day => day.id === parseInt(dia, 10))

              const diaIndex = this.listDays.findIndex(day => day.id === parseInt(dia, 10))

              this.formHorario[elegido].active = true

              const [one, two] = horario.split(';')

              const [first, second] = one.split('-')

              this.formHorario[elegido].horario[0].desde = first
              this.formHorario[elegido].horario[0].hasta = second
              this.formHorario[elegido].horario[0].active = true
              if (two.includes('ND')) {
                this.formHorario[elegido].horario[1].desde = ''
                this.formHorario[elegido].horario[1].hasta = ''
                this.formHorario[elegido].horario[1].active = false
              } else {
                const [hasta, desde] = two.split('-')
                this.formHorario[elegido].horario[1].desde = hasta
                this.formHorario[elegido].horario[1].hasta = desde
                this.formHorario[elegido].horario[1].active = true
              }

              const valor = `<div class="p-1"> ${this.listDays[diaIndex].value} <br>${convertTimeFormat(this.formHorario[elegido].horario[0].desde)} - ${convertTimeFormat(this.formHorario[elegido].horario[0].hasta)} ${this.formHorario[elegido].horario[1].active ? `<br> <span >${convertTimeFormat(this.formHorario[elegido].horario[1].desde)} - ${convertTimeFormat(this.formHorario[elegido].horario[1].hasta)}</span>` : ''} </div>`
              this.horarioAtencionShow.push({ id: this.formHorario[elegido].id, value: valor, color: this.listColors[color] })
              color += 1
              if (color === 5) {
                color = 0
              }
            })
          } else if (this.selectListCombo.id === 'HSA***') {
            this.horarioText = 'SIEMPRE ABIERTO'
          } else if (this.selectListCombo.id === 'HND***') {
            this.horarioText = 'Horario No Disponible'
          }
        } catch (err) {
          console.log('Usuario nuevo')
        }
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    cambiandoOption() {
      console.log(this.selectListCombo)
    },
    cleanHorario(horario) {
      if (!horario.active) {
        const item = this.formHorario.findIndex(day => day.id === horario.id)

        this.formHorario[item].horario[0].desde = ''
        this.formHorario[item].horario[0].hasta = ''
        this.formHorario[item].horario[1].desde = ''
        this.formHorario[item].horario[1].hasta = ''
        this.formHorario[item].horario[1].active = false
      }
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.validatorSubmit = true
      this.isLoading = true
      this.validateHorarios()
      if (this.validatorSubmit) {
        const directorio = JSON.parse(localStorage.getItem('empresa'))
        await store
          .dispatch('panel/EMPRESA_UPDATE_HORARIO', {
            horariosAtencion: this.horarioListString,
            idDirectorio: directorio.idDirectorio,
          })
          .then(() => {
            this.$refs.editServiciosForm.hide()
            this.notify('Operación Exitosa', 'Se ha actualizado los servicios', 'success')
            this.$emit('refresh')
          })
          .catch(() => {
            this.notify('Oops!', 'Ha sucedido algo vuelva a intentarlo', 'danger')
          }).finally(() => { this.isLoading = false })
      }
    },
    validateHorarios() {
      this.horarioListString = ''
      this.horarioListString = this.selectListCombo.id
      let ubication = 0
      if (this.selectListCombo.id === 'AHC***') {
        const cantidad = this.formHorario.find(hour => hour.active)
        if (cantidad === undefined) {
          this.notify('Oops!', 'Seleccionar al menos una día de la semana', 'info')
          this.validatorSubmit = false
          return
        }
        this.formHorario.forEach(hora => {
          if (hora.active) {
            if (ubication !== 0) {
              this.horarioListString += '**'
            }
            ubication += 1
            this.horarioListString += `${hora.id},`
            hora.horario.forEach((between, index) => {
              if (between.active) {
                if (index === 0) {
                  if (between.desde === '' || between.hasta === '') {
                    this.notify('Oops!', 'Se debe ingresar una hora valida para el horario principal', 'info')
                    this.validatorSubmit = false
                    return
                  }
                  this.horarioListString += `${between.desde}-${between.hasta}`
                }
                if (index === 1) {
                  if ((between.desde === '' || between.hasta === '')) {
                    this.horarioListString += ';ND'
                    return
                  }
                  this.horarioListString += `;${between.desde}-${between.hasta}`
                }
              } else if (index === 1) { this.horarioListString += ';ND' }
            })
          }
        })
      }
    },

    resetForm() {
      const newObject = [
        { id: 1,
          nombre: 'LUNES',
          active: false,
          horario: [
            { id: 'a', desde: '', hasta: '', active: true },
            { id: 'b', desde: '', hasta: '', active: false },
          ],
        },
        { id: 2,
          nombre: 'MARTES',
          active: false,
          horario: [
            { id: 'c', desde: '', hasta: '', active: true },
            { id: 'd', desde: '', hasta: '', active: false },
          ],
        },
        { id: 3,
          nombre: 'MIERCOLES',
          active: false,
          horario: [
            { id: 'e', desde: '', hasta: '', active: true },
            { id: 'f', desde: '', hasta: '', active: false },
          ],
        },
        { id: 4,
          nombre: 'JUEVES',
          active: false,
          horario: [
            { id: 'g', desde: '', hasta: '', active: true },
            { id: 'h', desde: '', hasta: '', active: false },
          ],
        },
        { id: 5,
          nombre: 'VIERNES',
          active: false,
          horario: [
            { id: 'j', desde: '', hasta: '', active: true },
            { id: 'k', desde: '', hasta: '', active: false },
          ],
        },
        { id: 6,
          nombre: 'SABADO',
          active: false,
          horario: [
            { id: 'l', desde: '', hasta: '', active: true },
            { id: 'm', desde: '', hasta: '', active: false },
          ],
        },
        { id: 7,
          nombre: 'DOMINGO',
          active: false,
          horario: [
            { id: 'n', desde: '', hasta: '', active: true },
            { id: 'o', desde: '', hasta: '', active: false },
          ],
        },
      ]
      return newObject
    },
    hide() {
      this.$refs.editServiciosForm.hide()
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const isLoading = ref(false)
    const isLoadingPostServ = ref(false)
    const listServicios = ref([])
    const listClassServicios = ref([])
    const vServicios = ref([])
    return { isLoading, listServicios, listClassServicios, vServicios, isLoadingPostServ, notify }
  },
}
</script>

<style>
#modal-edit-horario___BV_modal_body_ {
  min-height: 200px !important;
}
</style>>
