<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <profile-header
      :header-data="profileData.header"
      :empresa="aboutInfo"
      @refresh="getEmpresaLogged"
    />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <profile-about
            :about-data="aboutInfo"
            @refresh="getEmpresaLogged"
          />
          <profile-latest-photos
            :latest-images="aboutInfo.webDestacadas"
            :nombre="aboutInfo.nombreComercial"
            @refresh="getEmpresaLogged"
          />

          <!--           <ProfileFotoHead
            :empresa="aboutInfo"
            @refresh="getEmpresaLogged"
          /> -->
          <!--       <profile-suggested-pages :pages-data="profileData.suggestedPages" />
          <profile-twitter-feed :twitter-feed="profileData.twitterFeeds" /> -->
        </b-col>
        <!--/ about suggested page and twitter feed -->

        <!-- post -->
        <b-col
          lg="6"
          cols="12"
          order="1"
          order-lg="2"
        >
          <profile-post
            :empresa="aboutInfo"
            @refresh="getEmpresaLogged"
          />

          <profile-brochure
            :empresa="aboutInfo"
            @refresh="getEmpresaLogged"
          />
          <!-- datos contacto -->
          <!-- <profile-adicional
            :empresa="aboutInfo"
            @refresh="getEmpresaLogged"
          /> -->
          <!-- datos-adicionales-hotel -->
          <profile-a-list-room
            v-if="aboutInfo.tipoServicioTuristico.nombre === 'HOSPEDAJE'"
            :empresa="datosAdicionalesHospedaje"
            @refresh="getDatosAdicionales"
          />
          <profile-a-list-restaurant
            v-if="aboutInfo.tipoServicioTuristico.nombre === 'RESTAURANT'"
            :empresa="datosAdicionalesHospedaje"
            @refresh="getDatosAdicionales"
          />
          <profile-a-list-agencias
            v-if="aboutInfo.tipoServicioTuristico.nombre === 'AGENCIA DE VIAJES Y TURISMO'"
            :empresa="datosAdicionalesHospedaje"
            @refresh="getDatosAdicionales"
          />
          <ProfileVideoHead
            :empresa="aboutInfo"
            @refresh="getEmpresaLogged"
          />
        </b-col>
        <!-- post -->

        <!-- latest photos suggestion and polls -->
        <b-col

          lg="3"
          cols="12"
          order="3"
        >
          <ProfileHorarioA
            :horario="aboutInfo.horariosAtencion"
            @refresh="getEmpresaLogged"
          />
          <!-- SERVICIOS -->
          <profile-suggestion
            @refresh="getEmpresaLogged"
          />

          <!--  <profile-polls :polls-data="profileData.polls" /> -->
        </b-col>
        <!--/ latest photos suggestion and polls -->

        <!-- load more  -->
        <!--  <b-col
          cols="12"
          order="4"
        >
          <profile-bottom />
        </b-col> -->
        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import store from '@/store'
import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'
import ProfilePost from './ProfilePost.vue'
import ProfileAListRoom from './ProfileAListRoom.vue'
import ProfileAListRestaurant from './ProfileAListRestaurant.vue'
import ProfileAListAgencias from './ProfileAListAgencias.vue'
import ProfileVideoHead from './ProfileVideoHead.vue'
import ProfileLatestPhotos from './ProfileLatestPhotos.vue'
import ProfileHorarioA from './ProfileHorarioA.vue'
import ProfileBrochure from './ProfileBrochure.vue'

/*
import ProfileFotoHead from './ProfileFotoHead.vue'
import ProfileAdicional from './ProfileAdicional.vue'
import ProfileSuggestedPages from './ProfileSuggestedPages.vue'
import ProfileTwitterFeed from './ProfileTwitterFeed.vue'
import ProfilePolls from './ProfilePolls.vue'
import profileBottom from './profileBottom.vue'
*/
import ProfileSuggestion from './ProfileSuggestion.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    ProfileAListRoom,
    ProfileAListRestaurant,
    ProfileAListAgencias,
    ProfileHeader,
    ProfileAbout,
    ProfilePost,
    /* ProfileAdicional, */
    ProfileVideoHead,
    ProfileLatestPhotos,
    ProfileHorarioA,
    /*    ProfileFotoHead, */
    /*
    ProfileSuggestedPages,
    ProfileTwitterFeed,
    ProfilePolls,profileBottom, */
    ProfileSuggestion,
    ProfileBrochure,

  },
  data() {
    return {
      profileData: { },
      aboutInfo: { },
      loadingDatosAdicionales: false,
      datosAdicionalesHospedaje: {},
    }
  },
  async mounted() {
    const directorio = JSON.parse(localStorage.getItem('empresa'))
    this.aboutInfo = directorio
    await this.getDatosAdicionales()
    // this.getEmpresaLogged()
  },
  created() {
    this.$http.get('/profile/data').then(res => { this.profileData = res.data })
  },
  methods: {
    async getEmpresaLogged() {
      await store.dispatch('panel/EMPRESA_FIND_BY_RUC', {
        ruc: this.aboutInfo.ruc,
      }).then(async due => {
        // eslint-disable-next-line prefer-destructuring
        this.aboutInfo = due[0]
        localStorage.setItem('empresa', JSON.stringify(due[0]))
        await this.getDatosAdicionales()
      })
        .catch(error => {
          console.log(error)
        }).finally(() => { this.isLoading = false })
    },
    async  getDatosAdicionales() {
      this.loadingDatosAdicionales = true
      await store
        .dispatch('panel/EMPRESA_GET_DATO_ADICIONAL', {
          tipo: this.aboutInfo.tipoServicioTuristico.nombre,
          idDirectorio: this.aboutInfo.idDirectorio,
        })
        .then(datos => {
          this.datosAdicionalesHospedaje = datos
        })
        .catch(() => {
          console.log('Oops!', 'No se ha cargado los datos adicionales', 'danger')
        }).finally(() => { this.loadingDatosAdicionales = false })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
