<template>
  <b-card
    class="card-statistics"
  >
    <h5
      class="mb-2"
    >
      Datos adicionales
    </h5>
    <b-row>
      <BCol
        lg="4"
        cols="12"
        order="2"
        order-lg="1"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ empresa.nroSillas }}
              </h2>
              <span>Sillas</span>
            </div>
            <b-avatar
              :variant="`light-info`"
              size="45"
            >
              <font-awesome-icon
                icon=" fa-solid fa-chair"
                size="lg"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </BCol>
      <BCol
        lg="4"
        cols="12"
        order="2"
        order-lg="1"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ empresa.nroMesas }}
              </h2>
              <span>Mesas</span>
            </div>
            <b-avatar
              :variant="`light-primary`"
              size="45"
            >
              <font-awesome-icon
                icon=" fa-solid fa-border-all"
                size="lg"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </BCol>
    </b-row>

    <div class="text-center">
      <BButton
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="openAddBethroom"
      >
        Editar
      </BButton>
    </div>
    <!-- Modal de insert camas -->
    <b-modal
      id="modal-bethrooms"
      ref="insertBethrooms"
      centered
      title="Editar datos adicionales"
      no-close-on-backdrop
      size="m"
    >
      <template #modal-footer>
        <div class="float-right">
          <b-button-group size="sm">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mr-1"
              variant="secondary"
              @click="closeModal"
            >
              <feather-icon
                class="mr-50"
                icon="XIcon"
              />
              <span class="align-middle">Cancelar</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="saveBethRooms"
            >
              <feather-icon
                class="mr-50"
                icon="CheckIcon"
              />
              <span class="align-middle">Guardar</span>
            </b-button>
          </b-button-group>
        </div>
      </template>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <BRow>
          <BCol>
            <b-form-group
              label="# Sillas"
              label-for="sillas-input"
            >
              <b-form-input
                id="sillas-input"
                v-model="nroSillas"
              />
            </b-form-group>
          </BCol>

          <BCol>
            <b-form-group
              label="# Mesas"
              label-for="mesas-input"
            >
              <b-form-input
                id="mesas-input"
                v-model="nroMesas"
              />
            </b-form-group>
          </BCol>

        </BRow>

      </b-form>

      <!--   <b-card-text> qwe </b-card-text> -->
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  /* BMedia,
  BMediaAside,
  BMediaBody, */
  BAvatar,
  BModal,
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useNotify } from '@/helpers/toast'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BModal,
    BCardBody,
    BAvatar,
    /*    BMedia,
    BMediaAside,
    BMediaBody, */
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BButtonGroup,
  },

  directives: {
    /*  'b-modal': VBModal, */
    Ripple,
  },
  props: {
    empresa: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editListBethRooms: [],
      ListBethRoomsShow: [],
      listColors: [
        'light-info',
        'light-danger',
        'light-primary',
        'light-warning',
        'light-success'],
      items: [],
      isEdit: false,
      isLoading: false,
      nroSillas: '',
      nroMesas: '',
      nroHabitaciones: '',
      fieldsBethRoom: [
        { key: 'nombre', label: 'Descripción' },
        { key: 'precio', label: 'Precio S/. ' },
        'Acciones',
      ],
      numColor: 0,
      id: 0,
    }
  },
  watch: {
    empresa(newVal) {
      this.nroMesas = newVal.nroMesas
      this.nroSillas = newVal.nroSillas
      console.log(this.nroSillas)
    },
  },
  mounted() {
  },
  methods: {

    openAddBethroom() {
      this.$refs.insertBethrooms.show()
    },

    aceptar(id) {
      const index = this.editListBethRooms.findIndex(item => item.id === id)
      this.editListBethRooms[index].editable = false
    },
    async saveBethRooms() {
      this.isLoading = true

      const directorio = JSON.parse(localStorage.getItem('empresa'))

      const object = {
        idDatoAdicionalRestaurante: this.empresa.idDatoAdicionalRestaurante,
        idDirectorio: directorio.idDirectorio,
        nroMesas: /* parsear a entero */ parseInt(this.nroMesas, 10),
        nroSillas: parseInt(this.nroSillas, 10),
        estado: true,
      }
      await store
        .dispatch('panel/EMPRESA_POST_DATO_ADICIONAL', { model: object, tipo: 'RESTAURANT' })
        .then(datos => {
          if (datos.success) {
            this.notify('Operación Exitosa', 'Se ha actualizado los datos adicionales', 'success')
            this.$refs.insertBethrooms.hide()
            this.$emit('refresh')
          }
        })
        .catch(() => {
          this.notify('Oops!', 'No se ha actualizado la información', 'danger')
        }).finally(() => { this.isLoading = false })
    },
    closeModal() {
      this.$refs.insertBethrooms.hide()
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    return {
      notify,
    }
  },
}
</script>
