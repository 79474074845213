<template>
  <b-card
    class="card-statistics"
  >
    <h5
      class="mb-2"
    >
      Datos adicionales
    </h5>
    <b-row>
      <BCol
        lg="4"
        cols="12"
        order="2"
        order-lg="1"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ empresa.nroPisos }}
              </h2>
              <span>Pisos</span>
            </div>
            <b-avatar
              :variant="`light-info`"
              size="45"
            >
              <font-awesome-icon
                icon=" fa-solid fa-building"
                size="lg"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </BCol>
      <BCol
        lg="4"
        cols="12"
        order="2"
        order-lg="1"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ empresa.nroCamas }}
              </h2>
              <span>Camas</span>
            </div>
            <b-avatar
              :variant="`light-primary`"
              size="45"
            >
              <font-awesome-icon
                icon=" fa-solid fa-bed"
                size="lg"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </BCol>
      <BCol
        lg="4"
        cols="12"
        order="2"
        order-lg="1"
      >
        <b-card
          no-body
          class="border-primary"
        >
          <b-card-body class="d-flex justify-content-between align-items-center">
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ empresa.nroHabitaciones }}
              </h2>
              <span>Habitaciones</span>
            </div>
            <b-avatar
              :variant="`light-danger`"
              size="45"
            >
              <font-awesome-icon
                icon="fa-solid fa-person-booth"
                size="lg"
              />
            </b-avatar>
          </b-card-body>
        </b-card>
      </BCol>
    </b-row>

    <b-card-body>
      <div
        v-for="(lista,index) in ListBethRoomsShow"
        :key="index"
        class="employee-task d-flex justify-content-between align-items-center mb-2"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <BAvatar
              rounded
              size="42"
              :variant="lista.color"
            >
              <font-awesome-icon
                icon=" fa-solid fa-bed"
                size="lg"
              />
            </BAvatar>

          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="mb-0">
              {{ lista.nombre }}
            </h6>

          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="badge badge-light-success">
            S/. {{ formatNumberWithCommas(lista.precio) }}
          </span>
        </div>
      </div>
      <div class="text-center">
        <BButton
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="openAddBethroom"
        >
          Editar
        </BButton>
      </div>
    </b-card-body>
    <!-- Modal de insert camas -->
    <b-modal
      id="modal-bethrooms"
      ref="insertBethrooms"
      centered
      title="Editar datos adicionales"
      no-close-on-backdrop
      size="lg"
    >
      <template #modal-footer>
        <div class="float-right">
          <b-button-group size="sm">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="mr-1"
              variant="secondary"
              @click="closeModal"
            >
              <feather-icon
                class="mr-50"
                icon="XIcon"
              />
              <span class="align-middle">Cancelar</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="saveBethRooms"
            >
              <feather-icon
                class="mr-50"
                icon="CheckIcon"
              />
              <span class="align-middle">Guardar</span>
            </b-button>
          </b-button-group>
        </div>
      </template>
      <b-form class="pb-2 pl-0 pr-0 pt-1">
        <BRow>
          <BCol>
            <b-form-group
              label="# Pisos"
              label-for="pisos-input"
            >
              <b-form-input
                id="pisos-input"
                v-model="nroPisos"
              />
            </b-form-group>
          </BCol>

          <BCol>
            <b-form-group
              label="# Camas"
              label-for="camas-input"
            >
              <b-form-input
                id="camas-input"
                v-model="nroCamas"
              />
            </b-form-group>
          </BCol>
          <BCol>
            <b-form-group
              label="# Habitaciones"
              label-for="habitaciones-input"
            >
              <b-form-input
                id="habitaciones-input"
                v-model="nroHabitaciones"
              />
            </b-form-group>
          </BCol>

        </BRow>
        <BRow>
          <BCol
            cols="12"
            md="12"
          >
            <div>
              <BRow>
                <BCol
                  cols="12"
                  md="12"
                >
                  <div class="d-flex m-0 p-0 pb-1 justify-content-end">
                    <BButton
                      variant="primary"
                      size="sm"
                      @click="addTablebethRoom"
                    >
                      <span class="text-nowrap">Agregar tipo de habitación</span>
                    </BButton>
                  </div>
                </BCol>
              </BRow>
            </div>
            <div
              v-if="editListBethRooms.length == 0"
              class="demo-spacing-0 pt-1"
            >
              <BAlert
                variant="primary"
                show
              >
                <div class="alert-body">
                  <span><strong>SIN REGISTROS</strong> Haga click en 'Agregar tipo de habitación'
                    para insertar nuevo registro</span>
                </div>
              </BAlert>
            </div>
            <BTable
              v-else
              responsive
              :items="editListBethRooms"
              :fields="fieldsBethRoom"
              class="mb-0"
            >
              <template #cell(nombre)="data">
                <span v-if="!data.item.editable">
                  {{ data.item.nombre }}
                </span>
                <BRow v-else>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <BFormInput
                      id="nombre"
                      v-model="data.item.nombre"
                      placeholder=""
                    />
                  </b-col>
                </BRow>
              </template>
              <template #cell(precio)="data">
                <span v-if="!data.item.editable">
                  {{ formatNumberWithCommas(data.item.precio) }}
                </span>
                <BRow v-else>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <BFormInput
                      id="tiempo"
                      v-model="data.item.precio"
                      placeholder="S/. 0.00"
                    />
                  </b-col>
                </BRow>
              </template>
              <template #cell(acciones)="data">
                <span class="text-nowrap">
                  <span v-if="!data.item.editable">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="editBethroom(data.item.id)">
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Editar</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="deleteFromBethroom(data.item.id)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="mr-50"
                        />
                        <span>Eliminar</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                  <span v-else>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Guardar"
                      @click="accept(data.item.id)"
                    >
                      <feather-icon icon="CheckIcon" />
                    </b-button>
                  </span>
                </span>
              </template>

              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </BTable>
          </BCol>
        </BRow>
      </b-form>

      <BAlert
        v-if="editListBethRooms.some(item => item._rowVariant === 'success')"
        variant="warning"
        show
      >
        <div class="alert-body">
          <span>Para guardar las filas sombreadas de <strong>VERDE</strong> es necesario presionar el botón <strong>GUARDAR</strong>.</span>
        </div>
      </BAlert>

      <!--   <b-card-text> qwe </b-card-text> -->
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BModal,
  BCol,
  BTable,
  BDropdown,
  BRow,
  BDropdownItem,
  BButton,
  BAlert,
  BFormGroup,
  BFormInput,
  BForm,
  BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useNotify } from '@/helpers/toast'
import formatNumberWithCommas from '@/utils/number'

export default {
  components: {
    BRow,
    BDropdown,
    BDropdownItem,
    BTable,
    BCol,
    BCard,
    BModal,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BAlert,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BButtonGroup,
  },

  directives: {
    /*  'b-modal': VBModal, */
    Ripple,
  },
  props: {
    empresa: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editListBethRooms: [],
      ListBethRoomsShow: [],
      listColors: [
        'light-info',
        'light-danger',
        'light-primary',
        'light-warning',
        'light-success'],
      items: [],
      isEdit: false,
      isLoading: false,
      nroPisos: '',
      nroCamas: '',
      nroHabitaciones: '',
      fieldsBethRoom: [
        { key: 'nombre', label: 'Descripción' },
        { key: 'precio', label: 'Precio S/. ' },
        'Acciones',
      ],
      numColor: 0,
      id: 0,
    }
  },
  watch: {
    empresa(newVal) {
      this.nroCamas = newVal.nroCamas
      this.nroHabitaciones = newVal.nroHabitaciones
      this.nroPisos = newVal.nroPisos
      this.editListBethRooms = []
      this.ListBethRoomsShow = []
      try {
        newVal.tiposHabitaciones.split('***').forEach(item => {
          const spliteObj = this.getSplitTextList(item)
          this.editListBethRooms.push({ id: this.id, nombre: spliteObj[0], precio: spliteObj[1], color: this.listColors[this.numColor], editable: false })
          this.ListBethRoomsShow.push({ id: this.id, nombre: spliteObj[0], precio: spliteObj[1], color: this.listColors[this.numColor], editable: false })
          this.numColor += 1
          this.id += 1
          if (this.numColor === 4) {
            this.numColor = 0
          }
        })
      } catch (error) {
        console.log('usuario nuevo')
      }
    },
  },
  mounted() {
  },
  methods: {
    getSplitTextList(cadena) {
      const list = cadena.split('-')
      return list
    },
    openAddBethroom() {
      this.editListBethRooms = []
      this.ListBethRoomsShow.forEach(beth => this.editListBethRooms.push(beth))

      this.$refs.insertBethrooms.show()
    },
    addTablebethRoom() {
      // this.$refs.insertTarifa.hide()
      this.editListBethRooms.push({
        id: this.id,
        nombre: '',
        precio: '',
        color: this.listColors[this.numColor],
        editable: true,
      })
      this.numColor += 1
      if (this.numColor === 4) {
        this.numColor = 0
      }
      this.id += 1
      // this.clearFormTarifa()
    },
    editBethroom(id) {
      /* find in this.editListBethRooms with index id */
      const index = this.editListBethRooms.findIndex(item => item.id === id)
      this.editListBethRooms[index].editable = true
    },
    deleteFromBethroom(event) {
      const index = this.editListBethRooms.findIndex(item => item.id === event)
      this.editListBethRooms.splice(index, 1)
    },
    accept(id) {
      const foundItem = this.editListBethRooms.find(item => item.id === id)

      if (foundItem) {
        // eslint-disable-next-line no-underscore-dangle
        foundItem._rowVariant = 'success'
        foundItem.editable = false
      }
    },
    async saveBethRooms() {
      this.isLoading = true
      let stringeditListBethrooms = ''
      this.editListBethRooms.forEach((element, index) => {
        if (index !== 0) {
          stringeditListBethrooms += '***'
        }
        stringeditListBethrooms += `${element.nombre}-${element.precio}`
        /*  */
      })
      const directorio = JSON.parse(localStorage.getItem('empresa'))
      const object = {
        idDatoAdicionalHospedaje: this.empresa.idDatoAdicionalHospedaje,
        idDirectorio: directorio.idDirectorio,
        nroCamas: this.nroCamas,
        nroHabitaciones: this.nroHabitaciones,
        nroPisos: this.nroPisos,
        tiposHabitaciones: stringeditListBethrooms,
        estado: true,
      }
      await store
        .dispatch('panel/EMPRESA_POST_DATO_ADICIONAL', { model: object, tipo: 'HOSPEDAJE' })
        .then(datos => {
          if (datos.success) {
            this.notify('Operación Exitosa', 'Se ha actualizado los datos adicionales', 'success')
            this.$refs.insertBethrooms.hide()
            this.$emit('refresh')
          }
        })
        .catch(() => {
          this.notify('Oops!', 'No se ha actualizado la información', 'danger')
        }).finally(() => { this.isLoading = false })
    },
    closeModal() {
      this.$refs.insertBethrooms.hide()
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    return {
      notify,
      formatNumberWithCommas,
    }
  },
}
</script>
